<template>
    <div class="py-12 lg:py-24">
        <div class="container">
            <global-breadcrumb :breadcrumb="breadcrumb" is-light class="flex justify-center mb-4 lg:mb-11" />
            <h2
                class="max-w-[720px] mx-auto text-center text-2xl sm:text-35px lg:text-5xl font-semibold !leading-[1.25] tracking-[-0.96px] text-blacks-100 mb-3 lg:mb-6"
            >
                {{ dataGalleryDetail?.title }}
            </h2>
            <div
                class="max-w-[720px] mx-auto text-center text-base lg:text-xl leading-[150%] text-blacks-70 mb-10 lg:mb-24"
                v-html="dataGalleryDetail?.description"
            ></div>
            <div class="flex flex-wrap gap-3">
                <div
                    v-for="(item, index) in dataGalleryDetail?.images"
                    :key="index"
                    class="grid-item"
                    :class="{ 'grid-item--width2': listRandom.includes(`${index}`) }"
                >
                    <NuxtImg
                        :src="getThumbnail(item, 560, true)"
                        width="560"
                        sizes="sm:100vw md:50vw lg:560px "
                        loading="lazy"
                        :alt="item?.title"
                        :placeholder="[50, 25, 75, 5]"
                        class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const { getGalleryDetailBySlug } = useInspiration()
const dataGalleryDetail = await getGalleryDetailBySlug({ slug: useRoute().params.slug })
computed(() => dataGalleryDetail?.images)

const randomTwoNumbersInGroups = (arr: any) => {
    if (arr.length < 4) return []

    const result = []
    for (let i = 0; i < arr.length; i += 4) {
        const group = arr.slice(i, i + 4)
        result.push(...group.sort(() => Math.random() - 0.5).slice(0, 2))
    }
    return result
}

const listRandom = computed(() => randomTwoNumbersInGroups(Object.keys(dataGalleryDetail?.images)))

const breadcrumb = ref([
    {
        url: '/',
        title: 'Trang chủ'
    },
    {
        url: '/truyen-cam-hung',
        title: 'Truyền cảm hứng'
    },
    {
        url: '/thu-vien',
        title: 'Thư viện ảnh'
    },
    {
        url: dataGalleryDetail?.url,
        title: dataGalleryDetail?.title
    }
])
</script>
<style>
.grid-sizer,
.grid-item {
    width: calc((100% - 60px) / 6);
    height: 250px;
}

/* 2 columns */
.grid-item--width2 {
    width: calc((100% - 36px) / 3);
}
@media (max-width: 991px) {
    .grid-item,
    .grid-item--width2 {
        width: calc((100% - 12px) / 2);
    }
}
@media (max-width: 575px) {
    .grid-item,
    .grid-item--width2 {
        width: 100%;
    }
}
</style>
